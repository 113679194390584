import './component.css'

export function Navbar() {

    return (
        <div className='nav-bar'>
            <a 
                className='nav-button' 
                href="#about"
            >
                    About
            </a>
            <a 
                className='nav-button' 
                href="#projects"
            >
                    Projects
            </a>
            <a 
                className='nav-button' 
                href="#experience"
            >
                    Experience
            </a>
            <a 
                className='nav-button hide-small' 
                href="#skills"
            >
                    Skills
            </a>
            <a 
                className='nav-button hide-small' 
                href="#education"
            >
                    Education
            </a>
            <a 
                className='nav-button' 
                href="#contact"
            >
                    Contact
            </a>
        </div>
    )
}