import causalFunnel from './images/causalFunnel.png'
import vestmark from './images/vestmark.png'
import kdp from './images/kdp.png'

const experiences = [
    {
        title: "Software Quality Engineer Co-op",
        company: "Keurig Dr Pepper",
        description: [
            "Developed automated test scripts using Python, Appium, and Selenium to validate Android and iOS functionality, reducing manual testing by 20 hours/month",
            "Created automation scripts that helped achieve a 95% success rate for Bluetooth/Wi-Fi provisioning of Keurig machines",
            "Designed and executed backend API tests for Keurig mobile app, resolving 5+ critical issues and improving app stability and user experience",
            "Developed and implemented a PyTorch-based convolutional neural net to classify and match Keurig pod lid images, achieving 98% accuracy", 
        ],
        logo: kdp,
        date: "Jul 2024 - Present"
    },
    {
        title: "Frontend Engineer Intern",
        company: "Vestmark",
        description: [
            "Utilized React, TypeScript, Java, and AWS to create frontend and full-stack aspects of financial software", 
            "Automated file processing with an AWS Lambda function, reducing manual effort by 25%",
            "Conducted UI and API testing across 30+ pages using Jest and Selenium, ensuring high-quality deliverables"
        ],
        logo: vestmark,
        date: "Sep 2023 - Dec 2023"
    },
    {
        title: "Full-Stack Engineer Intern",
        company: "Causal Funnel",
        description: [
            "Created the company's official user dashboard using ReactJS, CSS, and AWS database services",
            "Developed scripts in Python, MySQL, and JavaScript to process website traffic data from over 10,000 users",
            "Optimized the user dashboard by implementing Redux, lazy loading, and code splitting in React, reducing load time by 40% and enhancing user experience"
        ],
        logo: causalFunnel,
        date: "Jun 2023 - Aug 2023"
    }
]

export default experiences