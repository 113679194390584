import resumeTop from '../images/resumeTop.png'
import resumeBottom from '../images/resumeBottom.png'

export function ResumeModal({ toggleOpen }) {

    return (
        <div className='resume'>
            <div className="top-bar">
                <a
                    className='resume-button download-button'
                    href="./Robert-Roach-Resume.pdf" download="Robert-Roach-Resume.pdf"
                >
                    Download PDF
                </a>
                <button
                    className='resume-button close-button'
                    onClick={toggleOpen}
                >
                    Close
                </button>
            </div>
            <div className='resume-img'>
                <img className='resume-top' src={resumeTop} />
                <img className='resume-bottom' src={resumeBottom} />
            </div>

        </div>
    )
}