import './experience.css'
import { ExperienceCard } from '../components/ExperienceCard'
import experiences from '../experiences'

export function Experience() {

    return (
        <div className='experience'>

            <h3 className="section-title">Experience</h3>
            
            <div className='experience-cards'>
                {experiences.map((experience, index) => (
                    <ExperienceCard 
                        key={index} 
                        experience={experience} 
                        last={index === experiences.length - 1 ? true : false}/>
                ))}
            </div>
        </div>
    )
}
