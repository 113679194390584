import React from 'react';
import './App.css';
import { About } from './pages/About'
import { Projects } from './pages/Projects'
import { Contact } from './pages/Contact'
import { Navbar } from './components/Navbar';
import { Experience } from './pages/Experience';
import { Skills } from './pages/Skills';
import { Education } from './pages/Education';

function App() {

  return (
    <div className='app' >

        <Navbar />
        <div className='content'>
          <About />
          <Projects />
          <div id='experience' />
          <Experience />
          <div id='skills' />
          <Skills />
          <div id='education' />
          <Education />
          <div id='contact' />
        </div>
        <Contact />
    </div>
  )
}

export default App;
