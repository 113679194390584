import './education.css'
import northeastern from '../images/northeastern.png'

const educations = [
    {
        school: "Northeastern University",
        degree: "Bachelor's of Computer Science",
        gpa: "3.9/4.0",
        description: [
            "Dean's List x4"
        ],
        date: "Sep 2021 - May 2025"
    }
]

export function Education() {

    return (
        <div className='education'>

            <h3>Education</h3>

            <div className='education-list'>
                {educations.map((education, index) => (
                    <div className='education-card'>
                        <img className='logo' src={northeastern}/>
                        <div className='education-content'>
                            <h5>{education.school}</h5>
                            <p>{education.degree}</p>
                            <p>{education.date}</p>
                            <p>GPA: {education.gpa}</p>
                            <p>{education.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}