import './experienceCard.css'

export function ExperienceCard({ experience, last }) {

    return (
        <div className='experience-card'>
            <div className="right-card">
                <div className='left-card'>
                    <div className={`line ${last ? '' : 'non-last-line'}`} />
                    <div className='experience-circle'>
                        <img className='logo' src={experience.logo} />
                        <div className="triangle-right" />
                    </div>
                </div>
                <div className='card-content'>
                    <h4>{experience.title}</h4>
                    <h5>{experience.company}</h5>
                    {experience.description.map((bullet, index) => (
                        <p className='bullet-point'>
                            - {bullet}
                        </p>
                    ))}
                </div>
                <div className="date">{experience.date}</div>
            </div>
        </div>
    )
}