import React from 'react';
import './projectCard.css'
import github from '../images/github.png'
import { Modal } from './Modal';
import { DemoModal } from './DemoModal';

export function ProjectCard({ details, hide }) {

    const [demoModalOpen, setDemoModalOpen] = React.useState(false)

    const toggleDemoModal = () => {
        setDemoModalOpen(!demoModalOpen)
    }

    return (
        <div className={`project-card ${hide ? "hide" : "show"}`}>
            {demoModalOpen
                ? 
                    <Modal 
                        toggleOpen={toggleDemoModal} 
                        content={<DemoModal toggleOpen={toggleDemoModal} demo={details.demo}/>}
                    />
                : <></>
            }
            <div className='card-left'>
                <div>
                    <h4>{details.title}</h4>
                    <p>{details.description}</p>
                </div>
                <div className='link-row'>
                    <a className="link" href={details.github}>
                        <img src={github} />
                        <div>Github</div>
                    </a>
                    {details.secondLink
                        ? <a className="link second-link" href={details.secondLink}>
                            <img src={details.secondLinkLogo} />
                            <div>Website</div>
                        </a>
                        : <></>
                    }
                    {details.demo
                        ? <button className='link demo' onClick={toggleDemoModal}>
                            <img src={details.demoLogo} />
                            Demo
                        </button>
                        : <></>
                    }
                </div>
            </div>

            <div className='card-right'>
                <img src={details.image} />
                {details.caption
                    ? <p>{details.caption}</p>
                    : <></>}
            </div>
        </div>
    )
}
