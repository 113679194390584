import React from 'react'
import { details } from '../projectDetails'
import './projects.css'
import { ProjectCard } from '../components/ProjectCard'
import $ from 'jquery'

export function Projects() {

    const [dropdownOpen, setDropdownOpen] = React.useState(false)

    const toggleDropdown = () => {
        $('body').on('scroll mousewheel touchmove', function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        })
        setDropdownOpen(!dropdownOpen)

        setTimeout(() => {
            $('body').off('scroll mousewheel touchmove', function (e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            })
        }, 100)
    }

    const featuredDetails = details.slice(0, 4)
    const dropdownDetails = details.slice(4)

    return (
        <div className='projects'>

            <h3 className='section-title'>Projects</h3>


            <div className='project-cards'>
                {featuredDetails.map((detail, index) => (
                    <ProjectCard key={index} details={detail} hide={false}/>
                ))}
            </div>

            <div className={`project-cards ${dropdownOpen ? '' : 'dropdown-cards'}`}>
                {dropdownDetails.map((detail, index) => (
                    <ProjectCard key={index} details={detail} hide={dropdownOpen ? false : true}/>
                ))}
            </div>

            <div className='bottom-row'>
                {dropdownOpen
                    ? <button className="project-expand-button" onClick={toggleDropdown}>
                        <div className="arrows down" />
                        Collapse
                    </button>
                    :
                    <button className="project-expand-button" onClick={toggleDropdown}>
                        <div className="arrows" />
                        <div>View More</div>
                        <div>({details.length - 4})</div>
                    </button>
                }
            </div>
        </div>
    )
}
