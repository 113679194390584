import React from 'react';
import profile from '../images/profile.png'
import { ResumeModal } from '../components/ResumeModal';
import { Modal } from '../components/Modal'
import linkedin from '../images/linkedin.png'
import github from '../images/github.png'
import './about.css'

export function About() {

    const [openResume, setOpenResume] = React.useState(false)

    const toggleOpen = () => {
        if (!openResume) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = ""
        }
        setOpenResume(!openResume)
    }

    return (
        <div className='about' id='about'>
            <div className='sub-title intro'>Hey, thanks for visiting. I'm</div>
            <div className='title'>Robert Roach</div>
            <div className='sub-title'>Aspiring software engineer, current</div>
            <div className='sub-title'>SQA co-op at Keurig Dr Pepper</div>
            <div className='about-buttons'>
                <a href='#contact' className='about-button'>
                    Contact
                </a>
                <button className='about-button' onClick={toggleOpen}>
                    View Resume
                </button>
                <a href='https://www.linkedin.com/in/robertpatrickroach' className='about-button'>
                    <img className='linkedin' src={linkedin} />
                    Linkedin
                </a>
                <a href='https://github.com/bobbyroach' className='about-button hide-small'>
                    <img className='github' src={github} />
                    Github
                </a>
            </div>

            {openResume 
                ? <Modal 
                    toggleOpen={toggleOpen} 
                    content={<ResumeModal toggleOpen={toggleOpen} />}
                />
                : <></>
            }
            <div className='about-me'>
                <h3>About Me</h3>
                <div className='about-me-content'>
                    <p>
                        I'm currently in fourth year computer science major at Northeastern University. I came into college with the aspiration to be an engineer, but ended up switching to computer science after a year. Since then, I've learned a variety of programming languages both inside and outside the classroom, with an emphasis on object-oriented development. Currently, I'm planning on getting my <span className='highlight'>master's degree in artifical intelligence from Northeastern.</span> Outside the classroom, I also have a passion for sports like golf and football, financial investing, and applying for internships on Linkedin. 
                        <br />
                        <br />
                        Currently, I'm working as a <span className='highlight'>software quality engineer co-op</span> at <span className='highlight'>Keurig Dr Pepper</span>, creating automation test scripts for the Kuerig mobile app. Additionally, I have <span className='highlight'>full-stack</span> and <span className='highlight'>frontend engineering</span> internship experience at <span className='highlight'>Vestmark</span> and <span className='highlight'>Causal Funnel</span>. 
                    </p>
                    <img
                        className='profile-pic'
                        src={profile} 
                    />
                </div>
            </div>
            <div id="projects"></div>
        </div>
    )
}