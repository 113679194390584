import React from 'react';
import './component.css'

export function Modal({ toggleOpen, content }) {

    return (
        <div className="modal-background" onClick={toggleOpen}>
            <div className='modal-box' onClick={(e) => e.stopPropagation()}>
                {content}
            </div>
        </div>
    )
}