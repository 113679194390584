import linkedin from '../images/linkedin.png'
import email from '../images/gmail.png'
import github from '../images/github.png'
import './contact.css'

export function Contact() {

    return (
        <div className="contact" id="contact">

            <h3>Contact</h3>

            <div className="contact-links">
                <a href='https://github.com/bobbyroach' className='contact-link'>
                    <img className="github-image" src={github} />
                    <div>GitHub</div>
                </a>

                <a href='https://www.linkedin.com/in/robertpatrickroach' className='contact-link'>
                    <img className='linkedin-image' src={linkedin} />
                    <div>Linkedin</div>
                </a>

                <a href='mailto:robertpatrickroach@gmail.com' className='contact-link'>
                    <img className='mail-image' src={email} />
                    <div>Email</div>
                </a>
            </div>
        </div>
    )
}