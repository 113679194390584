import './component.css'

export function DemoModal({ demo, toggleOpen }) {

    return (
        <div className='demo-modal'>
            <div className='top-bar'>
                <button
                    className='close-button'
                    onClick={toggleOpen}
                >
                    Close
                </button>
            </div>

            <video
                className='video'
                controls
            >
                <source src={demo} type="video/mp4" />
            </video>
        </div>
    )
}