import './skills.css'

export function Skills() {

    return (
        <div className="skills">

            <div className='skills-title'>
                <h3>Skills</h3>
            </div>

            <div className="skill-list">
                
                <div className='skill'>
                    <b>Certifications:</b>
                    <p>AWS Developer Associate</p>
                </div>
                <div className='skill'>
                    <b>Languages:</b>
                    <p>JavaScript, TypeScript, CSS, HTML, Python, Java, SQL</p>
                </div>
                <div className='skill'>
                    <b>Frameworks/Libraries:</b>
                    <p>React, Node.js, Redux, HTTP, Selenium, Pandas, PyTorch</p>
                </div>
                <div className='skill'>
                    <b>Tools:</b>
                    <p>AWS (Lambda, API Gateway, S3, DynamoDB), Git, Docker, Jira, Postman, Jenkins</p>
                </div>
            </div>
        </div>
    )
}