import MVP from './images/project_images/MVP.png'
import website from './images/project_images/website.png'
import rockDodgeGameplay from './images/project_images/rock_dodge_gameplay.mp4'
import instagram from './images/project_images/instagram.png'
import excel from './images/project_images/excel.png'
import rockDodge from './images/project_images/rock_dodge.png'
import chatbot from './images/project_images/chatbot_image.jpg'
import stockTrading from './images/project_images/stockTrading.png'
import internet from './images/internet.png'
import videoLogo from './images/videoLogo.png'

export const details = [
    {
        title: 'Husksheets Microsoft Excel Clone',
        description: "Designed a responsive and aesthetic frontend using React and TypeScript, and implemented common cell formulas. Utilized Node.js and MongoDB to create a REST API to manage user authentication, save spreadsheets, enable collaborative editing, and handle sheet creation and deletion.",
        image: excel,
        github: 'https://github.com/bobbyroach/husksheet-microsoft-excel-clone',
    },
    {
        title: 'Stock Market Reinforcement Learning',
        description: "Implemented a PyTorch-based Deep Q-Learning algorithm to predict big tech stock prices. Consists of two neural networks and a custom stock trading environment where the algorithm can buy, sell, or hold. Achieved a 50% success rate with the algorithm outperforming the traditional buy-and-hold strategy when trading a single stock over 10 years.",
        image: stockTrading,
        github: 'https://github.com/bobbyroach/stock-market-reinforcement-learning',
        caption: 'Algorithm total portfolio cash vs HPQ stock price over 10 years'
    },
    {
        title: 'Conversational Chatbot',
        description: "Turn-based conversational chatbot made from scratch with Tensorflow using an LSTM encoder-decoder model. Trained on 4 dialog datasets, the chatbot can correctly respond to some basic phrases, like 'hello,' I love you,' and 'what is up.' Even when it responds incorrectly, the sentences are still comprehensive.",
        image: chatbot,
        github: 'https://github.com/bobbyroach/conversational-chatbot',
    },
    {
        title: 'Instagram Clone',
        description: "Full stack instagram clone made with React.js and Bootstrap, using Firebase for an in-real-time database and user authentication. Allows for real-time photo upload, commenting and liking, searching and visiting other profiles, and direct messages.",
        image: instagram,
        github: 'https://github.com/bobbyroach/instagram-clone'
    },
    {
        title: "Neural Network Sentiment Analysis",
        description: "A project for my Natural Language Processing class where we try to guess the right star value out of five possible values for a review in the yelp review dataset. Using 4 different neural networks types with Tensorflow, achieved exact accuracy of 55% and within one star accuracy of 90%",
        github: 'https://github.com/bobbyroach/neural-network-sentiment-analysis'
    },
    {
        title: "Predicting NBA MVP with Machine Learning",
        description: "This project uses Python and the Scikit-Learn and Pandas Python libraries to predict the NBA MVP of a specified season based on every players regular season stats using three main steps.",
        image: MVP,
        github: "https://github.com/bobbyroach/NBA-Predict-MVP-with-ML#note"
    },
    {
        title: "Java Photo Editor with GUI",
        description: "Made with the MVC design pattern, this program acts as a collage photo editor with many features that allow the user make their desired picture collages. The user can add as multiple images, set filters on certain layers, add and manipulate certain layers, and save projects and images.",
        github: "https://github.com/bobbyroach/photo-editor"
    },
    {
        title: "Website Landing Page",
        description: "A simple webpage I created for a local business I worked at for a few summers. As a beginner project, it's made of plain JavaScript, HTML, and CSS.",
        image: website,
        github: "https://github.com/bobbyroach/Company-Webpage",
        secondLink: "https://www.oakcrestbuild.com/",
        secondLinkLogo: internet,
    },
    {
        title: "Rock Dodge Video Game",
        description: "Invested a simple video game using JavaScript, HTML, and CSS. This game involves moving a character around a 9 by 9 grid to dodge increasingly fast falling rocks.",
        github: "https://github.com/bobbyroach/rock-dodge",
        image: rockDodge,
        demo: rockDodgeGameplay,
        demoLogo: videoLogo,
    },
]